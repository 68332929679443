
import Vue from "vue";
import Chart from "@/components/common/Chart.vue";
import { Actions } from "@/store/models";
import {
  dashboard,
  DashboardTimePeriod,
  IDashboardData,
  IDashboardGetResponse,
} from "@/api";
import { IChartData, Labels } from "@/utils";

const ChartDataDefaults = {
  label: "Scans",
  backgroundColor: "transparent",
  borderColor: "#41a2ef",
  pointBackgroundColor: "white",
  lineTension: 0,
};

export default Vue.extend({
  name: "GroupProfileUsageStats",
  components: {
    Chart,
  },
  props: {
    groupId: Number,
    deviceId: Number
  },
  data() {
    return {
      timePeriods: Object.keys(DashboardTimePeriod).map((k: any) => ({
        key: k,
        val: DashboardTimePeriod[k as keyof typeof DashboardTimePeriod],
      })),
      timePeriod: DashboardTimePeriod.This_Week,
      totalScansData: {} as IDashboardGetResponse,
    };
  },
  computed: {
    totalScans: function (): IChartData {
      return this.getChartData(this.totalScansData);
    },
    scansCount: function (): number {
      return this.totalScansData.data?.data?.reduce(
        (total, d: IDashboardData) => {
          return d.count + total;
        },
        0
      );
    },
  },
  created() {
    this.getTotalScans();
  },
  methods: {
    async getTotalScans() {
      try {
        this.totalScansData = await dashboard.group.getScansByTimeAndMore(
          this.timePeriod,
          { groupId: this.groupId, deviceId: this.deviceId }
        );
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    getChartData(response: IDashboardGetResponse): IChartData {
      return {
        labels: response?.data?.label ? Labels[response.data.label] : [],
        datasets: [
          {
            data: response?.data?.label
              ? Labels[response.data.label].map(
                  (d: any) =>
                    response.data.data.find((data) => data.label === d)
                      ?.count || 0
                )
              : [],
            ...ChartDataDefaults,
          },
        ],
      };
    },
  },
  watch: {
    timePeriod: function () {
      this.getTotalScans();
    },
  },
});
