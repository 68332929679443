<template>
  <div style="height: 100%">
    <gmap-map
      :center="center"
      :zoom="15"
      :style="style"
      v-if="marker"
      :options="{ disableDefaultUI: true }"
    >
      <gmap-marker
        :position="marker"
        :clickable="true"
        @mouseover="label = true"
        @mouseout="label = false"
      />
      <gmap-info-window
        :opened="label"
        :position="center"
        :options="{
          pixelOffset: {
            width: 0,
            height: -35,
          },
        }"
      >
        <span style="color: black">{{ address }}</span>
      </gmap-info-window>
    </gmap-map>

    <div v-else>
      <p class="not-found">Location Not Found</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  props: {
    address: String,
    height: String,
  },
  data() {
    return {
      center: { lat: 45.508, lng: -73.587 },
      marker: undefined,
      label: false,
    };
  },
  computed: {
    style: function () {
      return "width: 100%; height: " + (this.height || "100%");
    },
  },
  mounted() {
    this.locate();
  },
  methods: {
    locate() {
      this.$gmapApiPromiseLazy().then(() => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;
        const geocoder = new window.google.maps.Geocoder();
        const address = vm.address;
        // console.log(address)

        geocoder.geocode({ address }, function (results, status) {
          // console.log(status, results)
          if (status === "OK") {
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            // console.log(lat, lng)

            vm.marker = {
              lat,
              lng,
              label: "test",
            };
            vm.center = vm.marker;
          } else {
            vm.marker = undefined;
          }
        });
      });
    },
  },
  watch: {
    address() {
      // console.log('address changed')
      this.locate();
    },
  },
};
</script>
<style lang="scss" scoped>
.not-found {
  text-align: center;
  padding-top: 50px;
  color: $secondary-color;
}
</style>