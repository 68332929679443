
import { IAssociate, IGroup } from "@/api";
import { getYear } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "GroupProfileUsers",
  props: {
    groupId: Number,
  },
  data() {
    return {
      test: false,
      getYear: getYear
    };
  },
  computed: {
    items: function (): IAssociate[] {
      const group: IGroup = this.$store.state.company?.groups?.find(
        (g: IGroup) => g.id == this.groupId
      );
      if (!group || !this.$store.state.company?.associates) return [];
      const userIds = new Set(
        group.associate_associates_groups.map((a) => a.associate.id)
      );
      return this.$store.state.company.associates.filter((a: IAssociate) =>
        userIds.has(a.id)
      );
    },
  },
  methods: {},
});
