
import Vue from "vue";
import { dashboard, IDashboardEquipmentsData, IGroup } from "@/api";
import PieChart from "@/components/common/PieChart.vue";
import randomColor from "randomcolor";

export default Vue.extend({
  name: "DashboardEquipments",
  components: {
    PieChart,
  },
  props: {
    id: Number
  },
  data() {
    return {
      groupId: 0,
      total: 0,
      chartData: {
        labels: [] as string[],
        datasets: [
          {
            backgroundColor: [] as string[],
            data: [] as number[],
          },
        ],
      },
    };
  },
  computed: {
  },
  methods: {},
  watch: {
    id: {
      immediate: true,
      handler: async function () {
        let r =  (await dashboard.equipments.getByGroup(this.id, (this as any).companyId)).data;
        
        const temp = {
          labels: [] as string[],
          datasets: [
            {
              backgroundColor: [] as string[],
              data: [] as number[],
            },
          ],
        };

        r.count_by_device_group.forEach((d) => {
          temp.labels.push(d.name);
          temp.datasets[0].data.push(d.count);
          temp.datasets[0].backgroundColor.push(
            randomColor({
              luminosity: "light",
            })
          );
        });

        this.chartData = temp
        this.total = r.total_device_count!;

      },
    },
  },
});
