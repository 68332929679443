
import { AssociateRoles, associates, IAssociate, IGroup } from "@/api";
import { Actions } from "@/store/models";
import { ASSOCIATE_ROLES } from "@/utils";
import Vue from "vue";
import AssignGroups from "@/components/common/AssignGroups.vue"

export default Vue.extend({
  name: "NewUserDailog",
  components: {
    AssignGroups
  },
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        middle_name: "",
        user_name: "",
      },
      roles: ASSOCIATE_ROLES,
      selectedRoles: [],
      selectedGroups: [] as number[],
    };
  },
  computed: {
    groups(): IGroup[] {
      return this.$store.state.company.groups;
    },
  },
  methods: {
    async sendInvite() {
      try {
        await associates.add({
          ...this.user,
          company: { id: this.$store.state.associate.company.id },
          roles: this.selectedRoles,
          associate_groups: this.selectedGroups,
        });
        await this.$store.dispatch(Actions.GetCompanyAssociates);
        this.user = {} as any;
        this.selectedRoles = [];
        this.$emit("toggleDialog", false);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    updateGroups(val: number[]) {
      this.selectedGroups = val
    }
  },
});
