
import { dashboard, IDashboardActivityLog } from "@/api";
import { getDate, getTime } from "@/utils";
import Vue from "vue";
import { getActivity } from "@/utils/activity";

export default Vue.extend({
  name: "GroupProfileActivityLog",
  components: {},
  props: {
    id: Number
  },
  data() {
    return {
      items: [] as any,
    };
  },
  created() {
    this.getItems();
  },
  methods: {
    async getItems() {
      this.items = (await dashboard.group.getActivityLogs(this.id)).data.map(
        (l) => ({
          datetime:
            getTime(l.created_datetime) + " • " + getDate(l.created_datetime),
          activity: getActivity(l),
        })
      );
    },
  },
});
