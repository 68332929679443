
import { dashboard, IDashboardLocation } from "@/api";
import { Actions } from "@/store/models";
import { getDate, getTime, getYear } from "@/utils";
import Vue from "vue";
import GMap from "@/components/common/GoogleMap.vue"

export default Vue.extend({
  name: "LocationHistory",
  components: {
    GMap
  },
  props: {
    id: Number,
    hideHeader: Boolean,
    dense: Boolean,
  },
  data() {
    return {
      items: [] as IDashboardLocation[],
      selected: {} as IDashboardLocation,
    };
  },
  computed: {
    itemsContainer: function () {
      return {
        height: "200px",
        "overflow-x": "auto",
      };
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    async getItems() {
      try {
        this.items = (await dashboard.group.getLocations(this.id)).data;
        if (!this.selected["project.id"]) {
          this.selected = this.items[0];
        }
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    select(item: IDashboardLocation) {
      this.selected = item;
      console.log(this.selected['project.address'])
    },
    isSelected(item: IDashboardLocation) {
      return item["project.id"] === this.selected["project.id"];
    },
    getDate(item: IDashboardLocation) {
      return (
        getDate(item["project.created_datetime"]) +
        " • " +
        getTime(item["project.created_datetime"])
      );
    },
  },
});
