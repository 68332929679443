
import Vue from "vue";
import { dashboard, multiApiOperations, projects } from "@/api";
import { Actions } from "@/store/models";
import { getDate, sizeInMB, sizeInMBNumber } from "@/utils";
export default Vue.extend({
  name: "GroupProfileGroupProjects",
  props: {
    id: Number,
  },
  data() {
    return {
      index: 1,
      selected: [],
      headers: [
        { text: "PROJECT NAME", value: "name" },
        { text: "LAST UPDATED", value: "dateEdited" },
        { text: "USERNAME", value: "username" },
        { text: "PROJECT SIZE", value: "size" },
        // { text: "GROUP NAME", value: "group" },
        { text: "STATUS", value: "status" },
        { text: "", value: "action" },
      ],
      items: [] as any,
    };
  },
  created() {
    this.getItems();
  },
  computed: {
    selectedIds: function (): number[] {
      return this.selected.map((p: { id: number }) => p.id);
    },
  },
  methods: {
    async getItems() {
      try {
        this.items = [];
        const r = (await dashboard.group.getLatestProjects(this.id)).data
          .projects;
        r.forEach((p) => {
          this.items.push({
            name: p.name,
            dateEdited: getDate(p.updated_datetime || p.created_datetime),
            username: p.updated_by || p.created_by,
            size: sizeInMBNumber(p.size),
            // group: p.projects_associate_groups
            //   .map((g) => g.associate_group.name)
            //   .join(", "),
            status: p.status === 0 ? "Locked" : "Unlocked",
            id: p.id,
          });
        });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError);
      }
    },

    download() {
      return;
    },
    async remove() {
      if (confirm("Are you sure to delete selected projects?")) {
        try {
          await multiApiOperations(projects.delete, this.selectedIds);
        } catch (e) {
          this.$store.dispatch(Actions.DisplayError);
        }
        this.getItems();
        this.$emit("getActivityLog");
        this.$store.dispatch(Actions.GetCompanyProjects);
      }
    },
    view(id: number) {
      this.$router.push("/projects/" + id);
    },
  },
});
