
import Vue from "vue";
import { ICompany, ICompanyAccount, IGroup } from "@/api";
import DashboardSupport from "@/components/common/dashboard/DashboardSupport.vue";
import NewUserModal from "@/components/groups/users/NewUserModal.vue";
import Users from "@/components/groups/groupProfile/GroupProfileUsers.vue";
import ActivityLog from "@/components/groups/groupProfile/GroupProfileActivityLog.vue";
import LocationHisotry from "@/components/common/dashboard/LocationHistory.vue";
import UsageStats from "@/components/common/dashboard/UsageStats.vue";
import GroupProjects from "@/components/groups/groupProfile/GroupProfileGroupProjects.vue";
import Equipements from "@/components/groups/groupProfile/GroupProfileEquipments.vue";
import { permissions } from "@/mixins";

export default Vue.extend({
  name: "GroupProfile",
  components: {
    Users,
    DashboardSupport,
    NewUserModal,
    ActivityLog,
    LocationHisotry,
    UsageStats,
    GroupProjects,
    Equipements,
  },
  mixins: [permissions],
  data() {
    return {
      newUserDialog: false,
    };
  },
  computed: {
    groupId: function (): number {
      return Number(this.$route.params.groupId);
    },
    group: function (): IGroup {
      return this.$store.state.company?.groups?.find(
        (g: IGroup) => g.id === this.groupId
      );
    },
    company: function (): ICompany {
      return this.$store.state.company;
    },
    account: function (): ICompanyAccount {
      return this.$store.state.company.account;
    },
    details: function (): any {
      const { cao } = this.$store.state.company?.account || {};
      return [
        {
          label: "Account Owner",
          text: `${cao?.first_name} ${cao?.last_name}`,
        },
        { label: "Company", text: cao?.company?.name },
      ];
    },
  },

  methods: {
    toggleNewUserDialog(dialog: boolean) {
      this.newUserDialog = dialog;
    },
    getActivityLog() {
      (this.$refs.activityLog as any).getItems();
    },
  },
});
